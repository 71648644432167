.dynamic-access-card-grid-media {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__item-description {
    margin: 0 $andes-spacing-8 $andes-spacing-8 $andes-spacing-8;
    font-size: $font-size-12;
    text-align: center;
    line-height: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;

    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  &__title {
    display: block;
    line-height: 15px;
    padding: $andes-spacing-8 $andes-spacing-8 0;
    text-decoration: none;
    font-weight: $font-weight-semibold;
    font-size: $font-size-12;
    color: $andes-gray-900;
    margin: 0;
    text-align: center;
  }

  &__pictures {
    margin: 0 $andes-spacing-8;
    img {
      height: 82px;
      object-fit: contain;
      margin: 0 auto;
      display: block;
    }
  }

  &__pictures--2 {
    height: 82px;
    .dynamic-access-card-grid-media__pictures-container {
      width: calc(50% - 2px);
      display: inline-flex;
    }

    .dynamic-access-card-grid-media__pictures-container:first-child {
      margin-right: $andes-spacing-4;
    }

    img {
      width: 100%;
      object-position: center;
    }
  }

  &__action.andes-button--small {
    border-radius: $andes-spacing-4;
    margin: 0 $andes-spacing-8 $andes-spacing-12 $andes-spacing-8;
    height: 26px;
  }
}
