@import './collections';

.collection {
  width: 100%;
  min-width: 300px;
  margin: 0;

  .main-item {
    display: none;
  }

  .other-items {
    margin: 0;
    width: 100%;

    .squared-item__wrapper {
      height: 174px;
      width: 50%;
      margin: 3.5px 0;
    }

    .squared-item {
      width: auto;
      height: 174px;
      margin: 0 3.5px;
      position: relative;

      img {
        height: 160px;
        width: 160px;
        transform: translateY(-50%);
        top: 50%;
        position: absolute;
      }
    }
  }
}

.collections {
  .andes-dropdown.andes-dropdown--form
    .andes-dropdown__trigger:focus:not(:focus-visible) {
    box-shadow: none;
  }

  .collections__header__dropdown {
    padding-top: 0;
  }

  .collections__header__dropdown .andes-card__content {
    width: 100%;
    margin: 0;
  }

  .andes-dropdown__trigger ~ div {
    width: 100%;
  }

  .section-header {
    margin: 0 4px;
  }

  .container {
    margin-left: 13px;
    margin-right: 13px;

    .section-button {
      margin: 3.5px 3.5px 0;
    }
  }

  .ui-loading {
    width: 52px;
    height: 52px;
    position: absolute;
    top: 50%;
    transform: translateY(50%);
    margin: 23px auto 0;
    left: 0;
    right: 0;
  }

  .collections__header {
    position: relative;
    width: 100%;
    overflow: inherit;
  }

  .andes-dropdown .andes-floating-menu .andes-dropdown__trigger {
    font-size: 18px;

    &:focus,
    &:active {
      outline: none;
      -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
    }
  }
}
