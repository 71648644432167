.quick-access-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-width: 57px;

 &__content {
  position: relative;

  .andes-thumbnail-container {
    position: relative;
  }
}

  &__label {
    font-size: $font-size-12;
    font-weight: 500;
    line-height: 12px;
    text-align: center;
    margin: 5px 0 0;
    padding-bottom: 1px;
    hyphens: auto;
    overflow-wrap: break-word;
  }

  &-anchor {
    text-decoration: none;
    color: $andes-gray-550;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgb(167 167 167 / 40%);

    &::before {
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
  }
  &__icon.tag.andes-thumbnail {
    display: block;
    margin: 0 auto;
    border-width: 1px;
  }

  &__icon.andes-thumbnail.tag--gray {
    border-color: $andes-gray-550-solid;
  }

  &__icon.andes-thumbnail.tag--red {
    border-color: $andes-error-red;
  }

  &__icon.andes-thumbnail.tag--accent {
    border-color: $andes-blue-500;
    box-sizing: content-box;
  }

  &__icon.andes-thumbnail.tag--green {
    border-color: $andes-success-green;
  }

  &__icon.andes-thumbnail.tag--orange {
    border-color: $andes-warning-orange;
  }

  &__pill.andes-badge.andes-badge--pill {
    position: absolute;
    min-width: 48px;
    display: block;
    top: unset;
    bottom: -3px;
    left: 50%;
    translate: -50% 0%;
  }

  &__icon {
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 12%);
    border: 1px rgb(0 0 0 / 0%) solid;
  }
}
