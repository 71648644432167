$gray: #e0e0e0;

@import './site-shopping-info';

.site-shopping-info {
  padding-bottom: 16px;

  .info-slide {
    img {
      display: inline-block;
    }
  }
}
