.payment-data {
  @import 'payment-data';
}

.payment-data {
  .container {
    margin: 0;
    padding: 0 0 32px;
  }

  .payment-data {
    &-container {
      border-radius: 0;
      text-decoration: none;
    }

    &-title {
      color: #333;
      font-size: 16px;
    }

    &-group-promo {
      justify-content: center;
    }

    &-section {
      height: auto;

      &-normal {
        flex: 0 1 auto;
        margin: auto;
        padding: 0;
      }

      &-promo {
        text-align: center;
        padding-bottom: 0;
        padding-top: 15px;
        width: 100%;

        &-logo {
          margin-top: -40px;
          flex: 0 1 auto;

          .payments-medium {
            margin: auto 10px;
          }
        }
      }
    }
  }
}
