// Globals
@import '~@andes/thumbnail/index';
@import '~@andes/badge/index';
@import '~@andes/common/lib/styles/visually-hidden';
@import '../../../styles/mixins';
@import '../../../styles/variables';

@import './home';
@import '../../../styles/fonts';
@import '~@andes/money-amount/lib/styles/combo';

// Global Css Vars
.main {
  --new-home-inline-margin: 16px;
}

// Shared
@import '../../commons/section/mobile';

.carousel-mobile {
  will-change: transform;
}

.home {
  overflow-x: hidden;
  .andes-carousel-snapped__hero {
    padding: 0 20px;

    .andes-carousel-snapped__container {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .poly-card--mobile{
    --poly-min-width: 142px;
  }
  .ui-recommendations-list.ui-recommendations-list__container--grid {
    .poly-card.poly-card--mobile {
      --poly-max-width: 240px;
    }
  }
}

@import '../../commons/squared-item/mobile';

// Components
@import './payment-data/mobile';
@import './news-row/mobile';
@import './advertising-adn/mobile';
@import './discovery/mobile';
@import './collections/mobile';
@import './registration/registration';
@import '../../commons/regret/style/mobile';
@import '../../commons/complaints-book/style/mobile';
@import '../../commons/financial-user-info/style/mobile';

@import '../../commons/dynamic-access/styles/mobile';
@import '../../commons/national-insurance-info/style/mobile';
@import '../../commons/help-email/style/mobile';
@import './loyalty-buylevel6/mobile/loyalty-buylevel6.mobile';
@import './loyalty-interstitial/loyalty-interstitial.mobile';
@import '../../commons/quick-access/styles/index';
@import '../../commons/quick-access/components/quick-access-item/styles/index';
@import '../../commons/label-card/index';
@import './categories/categories-new-design/mobile';
// Carousels
@import './carousel/carousel.mobile';
@import './official-stores/mobile';
@import './navigation/mobile';
@import './site-shopping-info/mobile';

// Recommendations
@import '../../commons/recommendations-list/mobile';
@import '../../commons/recommendations-single/mobile';

@import '../../commons/repurchase-widget/styles/mobile';
@import '../../commons/repurchase-card/mobile';

a {
  outline: none;
}
