.collection {
  display: flex;
  flex-wrap: wrap;
  margin-left: 8px;

  .main-item {
    width: 383px;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .other-items {
    display: flex;
    flex-wrap: wrap;
    margin: -8px 0 0 8px;
  }
}

.collections {
  .collections__header__dropdown {
    display: inline-block;
    position: static;

    .andes-card {
      width: 100% !important;
    }

    .andes-floating-menu .andes-dropdown__trigger {
      font-family: $font-family;
      background-color: transparent;
      border-width: 0;
      color: rgb(0 0 0 / 90%);
      cursor: pointer;
      font-weight: 600;
      line-height: 1;
      text-align: left;
      text-decoration: none;
      width: auto;
      box-shadow: none;
      padding: 4px 6px;
      margin: 0;
    }

    .andes-dropdown__standalone-arrow {
      width: 24px;
      height: 24px;
      fill: #3483fa;
    }

    .andes-list__item {
      height: 60px;
      box-sizing: border-box;
      border-bottom: 1px solid #e6e6e6;
      display: flex;
      padding: 0 32px;

      &.andes-list__item--selected {
        color: #3483fa;
      }

      :hover {
        background-color: rgb(245 245 245 / 80%);
      }
    }

    .andes-card__content {
      margin: 15px 0 0;
      max-height: 459px;
      overflow-y: auto;
      box-shadow: 0 1px 2px 0 rgb(0 0 0 / 30%);
      position: relative;
    }

    .andes-dropdown__trigger ~ div {
      inset: auto !important;
      margin: 0 !important;
      transform: none !important;
      left: 0 !important;
    }

    .andes-list__item-primary {
      background: none;
      border: 0;
      color: #333;
      cursor: pointer;
      display: block;
      font-weight: 300;
      line-height: 1.25;
      margin: 0;
      text-align: left;
      width: 100%;
      font-family: "Proxima Nova", -apple-system, "Helvetica Neue", Helvetica,
        Roboto, Arial, sans-serif;
      font-size: 16px;
    }

    .andes-list__item--selected::before {
      border-left: 4px solid #3483fa;
      top: 0;
      bottom: 0;
      left: 0;
    }

    .andes-list__item--selected .andes-list__item-primary {
      font-weight: 300;
    }

    .andes-floating-menu {
      position: static;
    }
  }
}
