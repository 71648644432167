@import '~@recommendations-fe/list/src/styles/recommendations-fe-list.mobile';

.ui-recommendations-list-section {
  margin: $andes-spacing-12 var(--new-home-inline-margin, 16px) 0;

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
}
