.dynamic-access-card-item {
  position: relative;

  &__item-container {
    padding: 0 $andes-spacing-8;
  }

  &__title {
    display: block;
    line-height: 15px;
    padding: $andes-spacing-8 $andes-spacing-8 0;
    text-decoration: none;
    font-weight: $font-weight-semibold;
    font-size: $font-size-12;
    color: $andes-gray-900;
    margin: 0;
  }

  &__title-hidden {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
  }

  &__title-hidden::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__image {
    height: 90px;
    width: 90px;
    object-fit: cover;
    margin: 0 auto;
    display: block;

    &--LANDSCAPE {
      height: 82px;
      width: 100%;
      margin-top: $andes-spacing-8;
    }
  }

  &__item-title::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &__item-title {
    line-height: $andes-spacing-12;
    margin: $andes-spacing-8;
    max-height: 2.6em;
    font-weight: $font-weight-regular;
    font-size: 10px;
    color: $andes-gray-550;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    overflow-wrap: anywhere;
    text-overflow: ellipsis;
    text-decoration: none;
  }

  &__item-highlight {
    & + .dynamic-access-card-item__item-title {
      margin-bottom: $andes-spacing-8;
      margin-top: 0;
    }
  }

  .ui-homes-icon--full {
    fill: $andes-green-500;
    vertical-align: middle;
    height: 10px;
    width: auto;
    position: relative;
    top: 1px;
    left: unset;
  }

  &__shipping {
    line-height: 10px;
    margin-top: 2px;
    align-items: center;
    flex-wrap: wrap;
    gap: $andes-spacing-4;
  }

  &__item-attributes {
    font-size: $font-size-10;
    color: $andes-gray-550;
    line-height: $line-height-s;
    font-weight: $font-weight-regular;
  }

  &__container-tag {
    margin: $andes-spacing-4 0;
    max-height: $andes-spacing-24;
  }

  &__container-tag svg {
    height: 9px;
    margin: auto $andes-spacing-4 auto auto;
    width: 13px;
  }

  &__container-tag span {
    border-radius: $andes-spacing-4;
    color: $andes-white;
    display: flex;
    font-size: $font-size-10;
    font-weight: $font-weight-semibold;
    height: fit-content;
    line-height: $line-height-s;
    padding: 3px 4px 2px;
    width: fit-content;
  }

  &__subtext {
    font-weight: $font-weight-regular;
    color: $andes-text-color-secondary;
    margin-left: 3px;
    line-height: 10px;
  }

  &__price {
    .andes-money-amount-combo__main-container {
      .andes-money-amount__currency-symbol,
      .andes-money-amount__fraction {
        font-weight: $font-weight-regular;
        font-size: $font-size-14;
        line-height: 18px;
        color: $andes-gray-900;
      }

      .andes-money-amount__suffix {
        font-size: $font-size-12;
        margin-left: 3px;
      }

      .andes-money-amount__cents {
        font-weight: $font-weight-semibold;
        font-size: 8px !important;
        line-height: 10px;
        color: $andes-gray-900;
        margin-top: 2px !important;
      }

      .andes-money-amount__discount {
        font-weight: $font-weight-semibold;
        line-height: $andes-spacing-12;
        color: $andes-green-500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
      }
    }

    .andes-money-amount-combo__previous-value {
      .andes-money-amount__currency-symbol,
      .andes-money-amount__fraction {
        font-size: $font-size-12;
      }

      .andes-money-amount__cents {
        font-size: 8px !important;
        margin-top: 1px !important;
      }
    }
  }

  &__items-images {
    margin: 0 8px;
    img {
      height: 82px;
      object-fit: contain;
      margin: 0 auto;
      display: block;
    }
    &__label {
      position: absolute;
      top: 40%;
      right: 40%;
      color: $andes-blue-500;
      font-weight: $font-weight-semibold;
      font-size: $font-size-18;
    }
  }

  &__items-images--2 {
    .dynamic-access-card-item__items-images-container:first-child {
      margin-bottom: 4px;
    }
  }

  &__items-images--3 {
    .dynamic-access-card-item__items-images-container:first-child {
      margin-bottom: 4px;
    }
    .dynamic-access-card-item__items-images-container:nth-child(2) {
      margin-right: 4px;
    }

    :nth-child(2),
    :nth-child(3) {
      height: 82px;
      width: calc(50% - 2px);
      display: inline-flex;
    }
    img {
      width: 100%;
      object-position: center;
    }
  }

  &__items-images--4 {
    .dynamic-access-card-item__items-images-container {
      height: 82px;
      width: calc(50% - 2px);
      display: inline-flex;
    }

    .dynamic-access-card-item__items-images-container:first-child {
      margin-bottom: 4px;
      margin-right: 4px;
    }

    .dynamic-access-card-item__items-images-container:nth-child(3) {
      margin-right: 4px;
    }

    img {
      width: 100%;
      object-position: center;
    }
  }

  &__items-images--last-image {
    height: 82px;
    width: calc(50% - 2px);
    display: inline-flex;
    position: relative;
    img {
      object-position: center;
    }
  }

  &__items-images--additional_items img {
    opacity: 0.5;
  }
}
