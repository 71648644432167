// @import './official-stores';

.official-stores {
  .andes-carousel-free {
    padding: 0 10px;
  }
}

.official-stores-slide {
  position: relative;
  outline: none !important;

  .title-wrapper {
    bottom: 0;
    left: 0;
    height: 66px;
    position: relative;
  }

  &-background {
    width: 328px;
    height: 115px;

    img {
      margin: -100px;
      width: auto;
      height: 124px;
    }
  }

  &-logo {
    position: absolute;
    height: 80px;
    bottom: 16px;
    left: 16px;
    margin: 0;

    img {
      width: 80px;
      height: 80px;
      border-radius: 8.1px;
      background-color: #fff;
      box-shadow: 0 3px 16px 0 rgb(0 0 0 / 10%);
    }
  }

  &-title {
    position: absolute;
    bottom: 50%;
    left: 119px;
    font-size: 16px;
    font-weight: 600;
    transform: translateY(50%);
  }
}
