@use 'sass:math';

.navigation {
  .andes-carousel-free__list {
    padding: 0 8px;
  }

  .squared-item {
    &__wrapper {
      width: 120px;
      height: 120px;
      margin: math.div(7px, 2);
    }

    img {
      width: 120px;
      height: 120px;
    }
  }

  .view-history-slide {
    text-decoration: none;

    .content {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 22px;
        height: 22px;
        margin-bottom: 8px;
      }

      span {
        text-align: center;
      }
    }
  }

  .carousel-mobile {
    margin-left: 6px;
  }
}
