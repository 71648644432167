.help-email {
  display: flex;
  padding-bottom: 27px;
  justify-content: center;
  align-items: center;
  background-color: $andes-white;

  &__link {
    font-weight: $font-weight-semibold;
    font-size: $font-size-16;
    line-height: $andes-spacing-20;
    color: $andes-gray-550-solid;
    text-decoration: none;
  }

  &__icon {
    margin-right: 5px;
    margin-bottom: -1px;
  }
}
