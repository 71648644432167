@import '../../../../commons/card/card-grid/card-grid';

.categories-new-design {
  background: $andes-white;
  margin: 12px var(--new-home-inline-margin, 16px) 0;
  border-radius: 6px;

  .container {
    padding: 14px;
    margin: 0;
  }

  .section-header {
    margin: 0;
    padding: $andes-spacing-16 14px;
    border-bottom: 1px solid $andes-gray-100;

    h2 {
      font-weight: $font-weight-semibold;
    }
  }

  .wrapper {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    @media screen and (width <= 360px) {
      grid-template-columns: repeat(3, minmax(85px, 1fr));
    }

    @media screen and (width <= 340px) {
      grid-template-columns: repeat(3, minmax(70px, 1fr));
    }

    grid-gap: $andes-spacing-8;
  }

  .section-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid $andes-gray-100;
    padding: $andes-spacing-16 14px;
    font-size: $font-size-14;

    .ui-homes-icon {
      &--chevron {
        height: 14px;
        stroke: $andes-blue-500;
        width: 9px;
      }
    }
  }

  .section-button__view-more {
    justify-content: flex-start;

    .ui-homes-icon {
      &--chevron {
        margin-left: 10px;
      }
    }

    .icon-chevron--more {
      transform: rotate(90deg);
      margin-top: 3px;
    }
  
    .icon-chevron--less {
      transform: rotate(-90deg);
      margin-top: 1px;
    }
  }

  .card-grid-anchor {
    color: $andes-gray-900;
  }

  .andes-card--flat {
    box-shadow: none;
    border: 1px solid rgb(238 238 238);
  }

  .andes-card__footer--border {
    border-top: none;
  }
}
