.dynamic-access-card-icon {
  .main {
    justify-content: center;
    margin-top: 30px;

    .title,
    .subtitle {
      color: $andes-gray-900;
      margin: 0 0 $andes-spacing-8 0;
    }

    svg {
      margin-bottom: $andes-spacing-8;
    }

    .description {
      -webkit-line-clamp: 3;
      max-height: 4.6em;
    }
  }
}

.dynamic-access-card-ilustrator {
  .main {
    justify-content: flex-start;
    margin-top: $andes-spacing-12;

    .title,
    .subtitle {
      color: $andes-blue-500;
      margin: 0;
    }
  }
}

.dynamic-access-card-icon,
.dynamic-access-card-ilustrator  {
  .main {
    img {
      margin: auto;
      margin-bottom: $andes-spacing-8;
      object-fit: fill;
    }
  }
}
