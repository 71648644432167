.card-grid {
  &-item {
    background-color: $andes-white;
    text-align: center;
    height: 133px;
    width: 100%;
    position: relative;

    &.andes-card--padding-8 .card-grid-footer {
      padding-top: 8px;
    }
  }

  &-container-image {
    height: 87px;
    background-color: $andes-gray-040-solid;

    & > img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  &-anchor {
    text-decoration: none;
    color: #737373;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    &::before {
      content: '';
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
  }

  &-footer {
    padding-top: 8px;
  }

  &-item__title {
    line-height: 15px;
    margin: auto;
    font-weight: $font-weight-semibold;
    font-size: $font-size-12;
    color: $andes-gray-550-solid;
    overflow: hidden;
    max-width: 80px;
    overflow-wrap: break-word;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgb(167 167 167 / 40%);
  }
}
