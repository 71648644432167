.dynamic-access-card {
  width: 136px;
  height: 206px;
  border-radius: $border-radius-6;
  background: $andes-white;

  &--with-discount {
    height: 218px;
  }

  &--with-highlight {
    height: 218px;
  }

  &--with-fs {
    height: 215px;
  }
}

.dynamic-access-card-common {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;

  .main {
    flex-direction: column;
    padding: 0 $andes-spacing-8;
    display: flex;

    .title,
    .subtitle {
      line-height: 15px;
      text-align: center;
      text-decoration: none;
      font-weight: $font-weight-semibold;
      font-size: $font-size-12;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      overflow-wrap: anywhere;
      text-overflow: ellipsis;
      max-height: 2.6em;
    }

    .description {
      line-height: 15px;
      text-align: center;
      color: $andes-black;
      margin: 0;
      font-weight: $font-weight-regular;
      font-size: $font-size-12;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      overflow-wrap: anywhere;
      text-overflow: ellipsis;
      max-height: 2.6em;
    }

    svg {
      align-self: center;
    }
  }

  .action {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: $andes-blue-500;
    border-radius: $andes-spacing-4;
    font-weight: $font-weight-semibold;
    font-size: $font-size-12;
    line-height: $andes-spacing-12;
    color: $andes-white;
    text-decoration: none;
    width: 104px;
    margin: 0 $andes-spacing-8 $andes-spacing-12;
    padding: 7px $andes-spacing-8;
  }

  .action::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}
