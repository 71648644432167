@use 'sass:math';

.squared-item {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  overflow: hidden;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%);
  position: static;
  border-radius: 4px;

  &__content {
    display: none;
  }

  &__wrapper {
    width: 107px;
    height: 107px;
    margin: math.div(7px, 2);
  }

  // Fix Iphone 5 image align
  img {
    margin: 0 auto;
    left: 0;
    right: 0;
  }
}
