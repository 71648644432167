@import '../../../../styles/fonts';
@import'../../highlight/styles/mobile';
@import './common.scss';
@import './card-icon-mobile';
@import './card-item-mobile';
@import './card-grid-media-mobile';

.dynamic-access {
  .andes-carousel-free__list.carousel-dynamic-access-mobile {
    margin: $andes-spacing-12 var(--new-home-inline-margin, 16px) 0;
  }
}
