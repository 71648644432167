.loyalty-buylevel6 {
  margin: $andes-spacing-12 var(--new-home-inline-margin, 16px) 0;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%);
  border-radius: $border-radius-6;
  background-color: $andes-white;
  overflow: hidden;

  &__tablet {
    margin: 0 24px;
  }

  &__header {
    padding: $andes-spacing-16;
    color: $andes-white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: linear-gradient(
      var(--deg_loyalty),
      var(--first_color_loyalty) var(--percentage_loyalty),
      var(--second_color_loyalty) 100%
    );

    &-fit-content {
      width: fit-content !important;
    }

    &__title {
      line-height: 22px;
      font-weight: $font-weight-semibold;
      font-size: $font-size-18;
      width: 100%;

      &--evolution {
        border-left: solid 2px $andes-white;
        margin-left: $andes-spacing-8;
        padding-left: $andes-spacing-8;
        display: flex;
        flex-direction: column;
      }

      &-img {
        height: $font-size-24;
        width: 59px;
      }

      &-price {
        flex-direction: row-reverse;
        align-items: center;
        gap: 4px;
        justify-content: flex-end;

        &--strike {
          color: $andes-white;

          .andes-money-amount__fraction::after,
          .andes-money-amount__currency-symbol::after {
            border-bottom: 1px solid $andes-white;
          }
        }

        &--value {
          .andes-money-amount__suffix {
            font-size: $font-size-18;
            color: $andes-white;
            margin: 0;
          }

          &--evolution {
            margin: 0;
          }
        }

        .andes-money-amount__currency-symbol {
          padding: 0;
        }
      }
    }
  }

  &__body {
    padding: $andes-spacing-16;

    &-title {
      font-size: $font-size-14;
      line-height: 18px;
      font-weight: $font-weight-semibold;
      display: block;
    }

    &-benefits {
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &-benefit {
      display: flex;
      align-items: center;

      &-logo {
        width: $andes-spacing-48;
        height: $andes-spacing-48;
        min-width: $andes-spacing-48;
        min-height: $andes-spacing-48;
        border: 1px solid $andes-gray-070;
        border-radius: $border-radius-6;
        overflow: hidden;

        &-img {
          width: 100%;
          height: auto;
        }
      }

      &-text {
        width: 100%;
        margin-left: 8px;
        font-size: $font-size-14;
        line-height: 18px;
      }
    }
  }

  &__button {
    border-top: solid 1px $andes-gray-070;
    padding: 13px 16px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ui-homes-icon--chevron {
      height: 14px;
      stroke: $andes-blue-500;
      width: 9px;
    }

    &-text {
      font-size: $font-size-14;
      font-weight: $font-weight-semibold;
      line-height: 18px;
      color: $andes-blue-500;
    }
  }
}

@media (width <= 300px) {
  .loyalty-buylevel6 {
    &__header {
      justify-content: space-between;

      &__container {
        &--left {
          &:first-child {
            width: max-content;
          }
        }
      }

      &-cents {
        font-size: $font-size-12;
      }

      &-price {
        font-size: $font-size-20;
      }

      &__title {
        &--promo {
          font-size: $font-size-12;
        }

        &--currency {
          font-size: $font-size-18;
        }
      }

      &-preprice {
        &--strike {
          font-size: $font-size-12;
        }
      }
    }
  }
}
