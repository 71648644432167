section.financial-user-info.financial-user-info {
  padding-bottom: 27px;
}

.financial-user-info {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  &__link {
    height: 58px;
    display: block;
  }
}
