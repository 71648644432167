@import '.';

section.complaints-book.complaints-book {
  padding-bottom: 20px;

  &__link {
    height: 56px;
  }

  &__image {
    width: 88px;
    height: 56px;
  }
}
