@import './widget';

.repurchase-widget {
  &-card {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    margin-bottom: 1px;
    border-radius: $border-radius-6;
    background: $andes-white;
    cursor: pointer;
  }

  &-header {
    border-bottom: 1px solid $andes-gray-070-solid;
    padding: $andes-spacing-12 $andes-spacing-16;
  }

  &__title {
    width: 100%;

    h2 {
      font-size: $font-size-18;
      margin: 0;
    }
  }

  &__item-title {
    font-size: $font-size-14;
  }

  &__action {
    display: block;
    margin: $andes-spacing-16 $andes-spacing-12 $andes-spacing-20 $andes-spacing-12;

    ::before {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__one-item-container {
    padding: 0;

    .repurchase-widget__image {
      margin-bottom: $andes-spacing-16;

      img {
        display: block;
        max-height: 250px;
        width: auto;
        margin: 0 auto;
      }
    }

    .repurchase-widget__item-description {
      margin: 0 $andes-spacing-16;
    }

    .repurchase-widget__item-description--with-actions {
      margin: $andes-spacing-12 $andes-spacing-16 $andes-spacing-16 $andes-spacing-16;
    }
  }

  &__items-images {
    height: 334px;

    .repurchase-widget__items-images__label {
      top: 30%;
    }
  }

  &__items-images--2 {
    margin-bottom: $andes-spacing-4;
  }

  &__3-items-container {
    height: 334px;
    margin: $andes-spacing-4;
  }

  &__items-images--4 {
    margin: $andes-spacing-4;

    img {
      max-width: 171px;
      max-height: 159px;
    }
  }

}

@media (width >= 410px) {
  .repurchase-widget__items-images--4 img {
    max-width: 100%;
  }
}