.news-row {
  margin: $andes-spacing-8 var(--new-home-inline-margin, 16px) 0;

  .container {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px;
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 4px;

    p {
      line-height: 26px;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: center;
    }

    .ui-homes-icon {
      vertical-align: text-top;

      &.ui-homes-icon--truck_icon {
        width: 16px;
        height: 16px;
      }
    }

    .andes-money-amount {
      display: inline-flex;

      @media screen and (width <= 335px) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline;
      }

      .andes-money-amount__currency-symbol,
      .andes-money-amount__fraction {
        font-size: 12px;
      }

      .andes-money-amount__cents {
        font-size: 8px !important;
      }
    }
  }
}
