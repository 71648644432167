section.national-insurance-info.national-insurance-info {
  padding-bottom: 27px;
}

.national-insurance-info {
  background-color: $andes-white;
  display: flex;
  align-items: center;
  justify-content: center;

  &__link {
    height: 58px;
    display: block;
  }

  &__image {
    margin-top: 17px;
    height: 40px;
  }
}
