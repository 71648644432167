@import '.';

section.regret.regret {
  padding-bottom: 23px;
}

.regret {
  &__box {
    width: auto;
    margin: 4px;
  }

  &__container {
    flex-direction: column;
    align-items: stretch;
    padding: 0 4px;
  }
}
