.home-registration {
  &-title {
    font-size: 18px;
    margin: 0 0 14px;
    font-weight: 600;
    color: rgb(0 0 0 / 80%);
    line-height: 22px;
  }

  &-buttons {
    .andes-button {
      width: 100%;

      &:first-child {
        margin: 0 0 12px;
      }
    }

    .secondary {
      background-color: rgb(65 137 230 / 15%);
      border: none;
      color: #3483fa;
    }
  }

  &-register {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: #666;

    a {
      color: #3483fa;
      text-decoration: none;
    }
  }

  .andes-card {
    margin: 0 var(--new-home-inline-margin, 16px);
  }
}
