@import '~@ads-placements-frontend/discovery/styles/index.mobile';

.discovery .advertising:first-child {
  margin-bottom: 0px;
}
.discovery .discovery__body.old_discovery {
  .advertising__image--home img {
    left: 60%;
  }
  .advertising:first-child {
    margin-bottom: 12px;
  }
}
