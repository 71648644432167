.label-card {
  margin: $andes-spacing-8 $andes-spacing-16 27px;

  .ui-home--hide {
    position: absolute;
    z-index: -1;
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    margin: 0 -1px -1px 0;
    overflow: hidden;
    padding: 0;
    display: block;
  }
}
