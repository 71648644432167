@import '~@andes/button/index';
@import '~@andes/modal/index';

.interstitial--old {
  width: 100%;
  height: 100%;

  .andes-modal__scroll {
    border-radius: 0;
  }

  .andes-modal__content {
    padding: unset;
    overflow: hidden;
  }

  iframe {
    border: 0;
    width: 100%;
    overflow: hidden;
    height: 100vh;
  }

  .andes-modal {
    &__content {
      padding: 0;
      width: 100%;
      height: 100%;
    }

    &__scroll {
      overflow: hidden;
    }

    &__overlay {
      padding: 0;
    }
  }
}

.andes-modal__portal {
  position: fixed;
  z-index: 999;
  width: 100%;
}

.interstitial {
  height: 100%;
  width: 100%;

  .andes-modal__scroll {
    border-radius: 0;
  }

  .andes-modal__content {
    padding: unset;
    overflow: hidden;
  }

  &-close-container {
    cursor: pointer;
    position: absolute;
    top: 2%;
    right: 5%;
  }

  &-image-container {
    width: 100%;
    height: 100vh;
    margin: auto;
    overflow: hidden;
    display: block;

    img {
      height: 100%;
      display: block;
      overflow: hidden;
      max-width: 100%;
      width: 100%;
      object-position: top;
    }
  }

  &-terms-container {
    background-color: $andes-white;
    position: absolute;
    bottom: 3%;
    width: 100%;
    margin: 0;
    margin-bottom: $andes-spacing-4;
    padding: 0 $andes-spacing-16;
    box-sizing: border-box;
    text-align: center;
    font-size: $font-size-14;
  
    span {
      padding: 0 $andes-spacing-12; 
      display: block;
      color: $andes-gray-900;
      
  
      a {
        margin: 0 3px;
        color: $andes-blue-base;
        text-decoration: none;
      }
    }
  }

  &-buttons-container {
    &--has_disclaimer { bottom: 12%; }
    background-color: $andes-white;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 0;

    .interstitial-buttons {
      display: flex;
      padding: 1rem 1rem 0;
      justify-content: center;
      flex-direction: column;

      button.andes-button {
        box-shadow: none;

        &--transparent {
          &:active,
          &:focus {
            background-color: $andes-white;
          }
        }
      }
    }
  }
}
