.home {
  .andes-carousel-snapped__hero {
    padding: 0 20px;

    .andes-carousel-snapped__container {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .andes-carousel-snapped__slide a .image-main-slider {
      border-radius: 7px;
    }
  }

  .andes-carousel-snapped__new-home {
    padding: 0 var(--new-home-inline-margin, 16px);
    background: linear-gradient($main-slider-background 50%, transparent);

    .andes-carousel-snapped__container {
      margin-top: 0;
      margin-bottom: $andes-spacing-8;
    }

    .andes-carousel-snapped__slide img {
      border-radius: 7px;
    }
  }
}

// TODO: remove .isEnableNewHomeWebMobile when migration is finished
.isEnableNewHomeWebMobile.nav-header::before {
  box-shadow: none;
}

// TODO: remove .isEnableNewHomeWebMobile when migration is finished
.isEnableNewHomeWebMobile .nav-bounds {
  a.nav-header-cp-anchor,
  a.nav-header-cp-anchor:hover,
  a.nav-header-cp-anchor:visited,
  a.nav-header-cp-anchor:active,
  a.nav-header-cp-anchor:link {
    box-shadow: none;
  }
}
